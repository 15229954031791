import $ from 'jquery';

export default function handleCardImageError(){
  $('.card__image img').on('error', function() {
    $(this).closest('.card').addClass('card--no-thumbnail');
    $(this).addClass('hide');
    $(this).next().removeClass('hide');
  });

  $('.item-header__image-wrapper img').on('error', function() {
    $(this).addClass('hide');
    $(this).next().removeClass('hide')
  });
}

