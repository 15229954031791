import Cookies from 'js-cookie';
import cookieOptions from './cookieOptions';

const PAGE_VIEW_COOKIE_NAME = 'pageViewCount';

export default function showHideBanner() {
  const banner = document.querySelector('.site-banner');

  if (banner) {
    const bannerId = banner.id;
    const BANNER_COOKIE_NAME = `${bannerId}_closed`;
    const pageViews = Cookies.get(PAGE_VIEW_COOKIE_NAME);
    const pageViewCount = pageViews ? parseInt(pageViews) : 1;

    // Show banner on second page visited, and subsequent pages if not closed
    const hideBanner = pageViewCount === 1 || Cookies.get(BANNER_COOKIE_NAME);
    if (hideBanner) banner.classList.add('hide')
      else banner.classList.remove('hide');

    const buttons = banner.querySelectorAll('.button');
    buttons.forEach(button => {
      button.addEventListener('click', () => {
        banner.classList.add('hide');

        Cookies.set(BANNER_COOKIE_NAME, true, cookieOptions);
      });
    });
  }
}
