export default function optionSelected() {
  const CSS_CLASS = 'js-has-selected-option';
  const selects = document.querySelectorAll('select');

  selects.forEach(select => {
    const hasSelectedOption = select.options.selectedIndex !== 0;

    if (hasSelectedOption) {
      select.classList.add(CSS_CLASS);
    }

    select.addEventListener('change', (event) => {
      // This adds/removes the bolding (as appropriate) while the page is reloading

      if (event.currentTarget.selectedIndex === 0) {
        select.classList.remove(CSS_CLASS);
      } else {
        select.classList.add(CSS_CLASS);
      }
    });
  });
}
