import $ from 'jquery';

export default function setLocationButtonBackground() {
  const DROPDOWN_OPEN_CLASS = 'js-dropdown-open';
  const exploreLocationsSection = document.querySelector('.locations-mobile');

  document.addEventListener('click', (event) => {
    const targetClassList = event.target.classList;targetClassList;

    if (targetClassList.contains('dropdown-pane-button') || targetClassList.contains('dropdown-pane-button__title')) {
      $('html, body').css('overflow', 'hidden auto');

      preventBodyScroll(targetClassList);

      exploreLocationsSection.classList.toggle(DROPDOWN_OPEN_CLASS);
    } else {
      exploreLocationsSection.classList.remove(DROPDOWN_OPEN_CLASS);
    }
  });

  function preventBodyScroll(target) {
    if (target.contains('js-mobile-location-panel')) {
      var scrollState = $('html, body').css('overflow');

      if (scrollState !== 'hidden') {
        $('html, body').css('overflow', 'hidden');
      }
    }
  }
}
