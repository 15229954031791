// This file is automatically compiled by Vite, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'foundation-sites';

import $ from 'jquery';

import boldSelectedOption from '../packs/boldSelectedOption';
import embedSocialMedia from '../packs/embedly';
import showHideBanner from '../packs/banner';
import firefoxEqualizerBugFix from '../packs/firefoxEqualizerBugFix';
import hideExploreDropdownContainer from '../packs/hideExploreDropdownContainer';
import itemDetails from '../packs/itemDetails';
import jumpToSearchHeader from '../packs/searchHeader';
import linkifyTextUrl from '../packs/linkifyTextUrl';
import openCulturalWarning from '../packs/openCulturalWarning';
import openModalByDefault from '../packs/openModalByDefault';
import openTooltipOnClick from '../packs/tooltip';
import scrollTo from '../packs/scrollTo';
import setLocationButtonBackground from '../packs/locationButton';
import showMaps from '../packs/showMaps';
import submitFormOnChange from '../packs/submitFormOnChange';
import switchControl from '../packs/switchControl';
import viewMore from '../packs/viewMore';
import waveColourPicker from '../packs/waveColourPicker';
import defaultResultView from '../packs/defaultResultView';
import sortOnChange from '../packs/sortOnChange';
import handleCardImageError from '../packs/handleCardImageError';
import copyToClipboard from '../packs/copyToClipboard';
import scrollToUsageRightsAccordion from '../packs/scrollToUsageRightsAccordion';

$(function () {
  Foundation.addToJquery($);
  $(document).foundation();
  boldSelectedOption();
  embedSocialMedia(window, document);
  firefoxEqualizerBugFix();
  hideExploreDropdownContainer();
  itemDetails();
  jumpToSearchHeader();
  linkifyTextUrl();
  openCulturalWarning();
  openModalByDefault();
  openTooltipOnClick();
  scrollTo();
  setLocationButtonBackground();
  showHideBanner();
  showMaps();
  submitFormOnChange();
  switchControl();
  viewMore();
  waveColourPicker();
  defaultResultView(); // must be after switchControl
  sortOnChange();
  handleCardImageError();
  copyToClipboard();
  scrollToUsageRightsAccordion();
});
