import $ from 'jquery';

export default function openModalByDefault() {
  const MODAL_CLASSNAME = 'reveal';
  const MODAL_DATA_ATTRIBUTE = 'data-open-by-default';
  const modals = document.querySelectorAll(`.${MODAL_CLASSNAME}`);

  modals.forEach(modal => {
    const DESKTOP_WIDTH = 1024;
    const isDesktop = window.innerWidth >= DESKTOP_WIDTH;

    if (isDesktop) return;

    const openByDefault = modal.getAttribute(MODAL_DATA_ATTRIBUTE) === 'true';

    if (openByDefault) {
      $(modal).foundation('open');
    }
  });
}
