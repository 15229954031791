import $ from 'jquery';

export default function submitFormOnChange() {
  const submitOnLabelClick = document.querySelectorAll('.js-submit-form-on-label-click');

  submitOnLabelClick.forEach(item => {
    const input = item.querySelector('input');
    const isChecked = input.checked == true;

    item.addEventListener('click', () => {
      const form = item.closest('.js-search-filters');
      input.checked = isChecked ? false : true;

      form.requestSubmit();
    })
  });

  $('.slider').on('changed.zf.slider', (event) => {
    const form = event.target.closest('.js-search-filters');

    form.requestSubmit();
  });

  $('.js-search-filters').on('submit', (event) => {
    const form = event.target;
    Array.from(form.elements).forEach((element) => {
      if (element.name === 'creation_date_start') {
        element.disabled = element.value === element.min;
      }
      if (element.name === 'creation_date_end') {
        element.disabled = element.value === element.max;
      }
    });

    form.submit();
  });
}
