const SCROLL_TO_ELEMENT_CLASS = 'js-scroll-to';

export default function scrollTo() {
  const elementToScrollTo = document.querySelector(`.${SCROLL_TO_ELEMENT_CLASS}`);

  if (elementToScrollTo) {
    elementToScrollTo.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
}
