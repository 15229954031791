import Cookies from 'js-cookie';
import $ from 'jquery';

export default function switchControl() {
  const COOKIE_OPTIONS = { expires: 365 };

  const switchControls = document.querySelectorAll('.button-group--switch');

  switchControls.forEach(switchControl => {
    switchControl.addEventListener('click', event => {
      const cookieName = switchControl.getAttribute('data-switch-cookie-name');
      const values = JSON.parse(switchControl.getAttribute('data-switch-values'));
      const target = switchControl.getAttribute('data-switch-target');

      const currentCookie = Cookies.get(cookieName) || values[0];
      const newValue = values.find(item => item.toString() !== currentCookie);
      const newValuePos = values.indexOf(newValue);

      if (['uat', 'staging', 'production'].includes(import.meta.env.RAILS_ENV)) {
        COOKIE_OPTIONS.secure = true;
      }

      Cookies.set(cookieName, newValue, COOKIE_OPTIONS);

      if (target == 'reload') {
        location.reload();
      } else {
        setTargetData(target, cookieName, newValue);
        updateButtonState(switchControl, newValuePos);
      }
    });
  });
}

function setTargetData(target, cookieName, value) {
  const results = document.querySelectorAll(target);
  results.forEach(result => {
    result.setAttribute('data-'+cookieName, value);
    const elem = new Foundation.Equalizer($(result));
    elem.applyHeight();
  });
}

function updateButtonState(control, selectedPosition) {
  const buttons = control.querySelectorAll('button');
  buttons.forEach((button, index) => {
    if (index === selectedPosition) {
      button.classList.add('button--selected');
    } else {
      button.classList.remove('button--selected');
    }
  });
}