import Cookies from 'js-cookie'
import cookieOptions from './cookieOptions';
import detectRobot from './detectRobot';
import $ from 'jquery';

export default function openCulturalWarning() {
  if (detectRobot(navigator.userAgent)) return;

  const COOKIE_NAME = 'culturalWarningAccepted';
  const culturalWarningModal = document.querySelector('#cultural_warning');

  if (!Cookies.get(COOKIE_NAME)) {
    $(culturalWarningModal).foundation('open');
  }

  const closeButton = culturalWarningModal.querySelector('button');

  closeButton.addEventListener('click', () => {
    Cookies.set(COOKIE_NAME, true, cookieOptions());
  })
}
