export default function hideExploreDropdownContainer() {
  const inputSearchBar = document.getElementById('search_term');

  if (inputSearchBar) {
    inputSearchBar.addEventListener('focus', function () {
      const panel = document.getElementsByClassName('dropdown-pane is-open');
      panel[0].classList.remove('is-open');
    });
  }
}
