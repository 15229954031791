import $ from 'jquery';

export default function scrollToUsageRightsAccordion() {
  $('.js-scroll-to-usage-rights-accordion').on('click', function(event) {
    event.preventDefault();
    const elementToScrollTo = document.querySelector('#usage-rights');

    $('.accordion').foundation('down', $('#usage-rights'));

    if (elementToScrollTo) {
      elementToScrollTo.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  });
}
