import Cookies from 'js-cookie';

export default function defaultResultView() {
  const COOKIE_NAME = 'searchView';
  const RESULTS = '.results';
  const SWITCH_CONTROLS = '.button-group--switch[data-switch-cookie-name="searchView"]'

  if (Cookies.get(COOKIE_NAME) === undefined && isMobile()) {
    const results = document.querySelectorAll(RESULTS);
    results.forEach(result => {
      result.setAttribute('data-'+COOKIE_NAME, 'list');
    })

    const switchControls = document.querySelectorAll(SWITCH_CONTROLS);
    switchControls.forEach(control => {
      const buttons = control.querySelectorAll('button');
      buttons.forEach((button, index) => {
        if (index === 1) {
          button.classList.add('button--selected');
        } else {
          button.classList.remove('button--selected');
        }
      });
    })
  }
}

function isMobile() {
  return window.innerWidth < 768;
}