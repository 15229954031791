import $ from 'jquery';

const TOOLTIP_LABEL_CLASS = 'tooltip-label';

// This allows the tooltip to be activated by clicking on its containing label.

export default function openTooltipOnClick() {
  const tooltipLabels = document.querySelectorAll(`.${TOOLTIP_LABEL_CLASS}`);

  tooltipLabels.forEach(tooltipLabel => {
    tooltipLabel.addEventListener('click', () => {
      const tooltipSpan = tooltipLabel.querySelector('span');

      $(tooltipSpan).foundation('show');
    });
  });
}
