import linkifyHtml from 'linkify-html';
const LINKIFY_CSS_CLASS = 'js-linkify';

export default function linkifyTextUrl() {
  const linkifySections = document.querySelectorAll(`.${LINKIFY_CSS_CLASS}`);

  linkifySections.forEach(linkifySection => {
    const paragraphs = linkifySection.querySelectorAll('p');

    paragraphs.forEach(paragraph => {
      const linkifiedParagraph = linkifyHtml(paragraph.innerText, {
        defaultProtocol: 'https'
      });

      paragraph.innerHTML = linkifiedParagraph;
    });
  });
}
