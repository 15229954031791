import $ from 'jquery';

export default function waveColourPicker() {
  $('.wave-up-svg, .wave-down-svg').each(function (_index) {
    const colorClass = $(this).parent().parent().attr('class');
    const currentClass = $(this).attr('class');

    // clean up the class
    $(this).removeClass();

    $(this).addClass(`${currentClass} ${colorClass}`);
  });
}
