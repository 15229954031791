export default function showMapPanel() {
  const showPanelContainers = document.querySelectorAll('.js-show-maps-container');

  showPanelContainers.forEach(container => {
    const hoverableItems = container.querySelectorAll('.js-show-panel-item');
    const currentLocationMap = container.querySelector('.js-location-map-image:not(.hide)');

    hoverableItems.forEach(item => {
      const itemLink = item.querySelector('a');
      const mapSelector = Array.from(item.classList).find(className => className.includes('js-location-map'));
      const mapImage = container.querySelector(`#${mapSelector}`);

      if (!mapImage) return;

      item.addEventListener('mouseenter', () => showMap(currentLocationMap, mapImage));
      item.addEventListener('mouseleave', () => hideMap(currentLocationMap, mapImage));

      itemLink.addEventListener('focus', () => showMap(currentLocationMap, mapImage));
      itemLink.addEventListener('blur', () => hideMap(currentLocationMap, mapImage));
    })
  })
}

function showMap(currentLocationMap, mapImage) {
  if (currentLocationMap) currentLocationMap.classList.add('hide');

  mapImage.classList.remove('hide');
}

function hideMap(currentLocationMap, mapImage) {
  mapImage.classList.add('hide');

  if (currentLocationMap) currentLocationMap.classList.remove('hide');
}
