/*
INSTRUCTIONS FOR USE

1. Add .js-view-more-container to the element containing all of your items
2. On that same element, add a data attribute visible-item-count with the visibleItemCount (the number of items that should be displayed initially, and then each time you click View More)
3. Add .js-view-more-item to each item
4. Add .hide (Foundation class) to each item that's outside the visibleItemCount
5. Add a button with .js-view-more-button (this needs to be inside the container)
6. Remember to only show the button if there are more items than the visibleItemCount. NOTE: The JS code will hide the View More button when relevant.
*/

export default function viewMore() {
  const viewMoreContainers = document.querySelectorAll('.js-view-more-container');

  viewMoreContainers.forEach(container => {
    const viewMoreButton = container.querySelector('.js-view-more-button');

    viewMoreButton.addEventListener('click', () => {
      showNextItems(container, viewMoreButton);
    })

    viewMoreButton.addEventListener('keypress', (event) => {
      if (event.key === 'Enter') {
        showNextItems(container, viewMoreButton);
      }
    })
  })
}

function showNextItems(container, viewMoreButton) {
  const visibleItemCount = container.dataset.visibleItemCount;
  const hiddenItems = container.querySelectorAll('.js-view-more-item.hide');
  const itemsToReveal = [...hiddenItems].slice(0, visibleItemCount);

  // Show next row/section of items
  itemsToReveal.forEach(item => item.classList.toggle('hide'));

  // Hide View More button if relevant
  if (hiddenItems.length <= visibleItemCount) {
    viewMoreButton.classList.toggle('hide');
  }
}
