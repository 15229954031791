import $ from 'jquery';

export default function copyToClipboard() {
  $('.js-copy-to-clipboard').on('click', function(event) {
    var link = $(this).data('url');

    navigator.clipboard.writeText(link).then(function() {
      const initialText = event.target.innerHTML;

      event.target.innerHTML = 'Link copied!';

      setTimeout(function() {
        event.target.innerHTML = initialText;
      }, 1000);
    });
  });
}